import http from '../axios'

class ValidatorService {

  validateXml (xml) {

    const formData = new FormData()
    formData.append('xml', xml)
    return http.post('/validateXML', formData)
  }

  /* Validate PDF */

  validatePdf (pdf) {
    const formData = new FormData()
    formData.append('pdf', pdf)
    return http.post('/validatePDF', formData)
  }

  /* validate constancy */

  validateNom (pdf, nom) {
    const formData = new FormData()
    formData.append('document', pdf)
    formData.append('nom151', nom)
    return http.post('/validateConstancy', formData)
  }

  /* validate signature */
  validateSignature (file, signture, algorithm, cert) {
    const formData = new FormData()
    formData.append('document', file)
    formData.append('signature', signture)
    formData.append('algorithm', algorithm)
    formData.append('cert', cert)
    return http.post('/validateSign', formData)
  }
}

export default new ValidatorService()
