<template>
  <div class="container">
    <vs-row>
      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
        <h1 class="text-primary">Validador de Constancia</h1>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col>
        <div class="my-5">
          Valida que la constancia NOM-151 corresponda con un documento. Para ello deberás cargar el documento PDF
          que deseas validar y la constancia de conservación del mensaje de datos en formato ASN.1.
        </div>
      </vs-col>
    </vs-row>

    <div class="mt-5" v-if="!result">

      <!-- ARCHIVO PDF -->
      <div class="mt-5">
        <!--<vs-divider position="left"><b>Archivo PDF</b></vs-divider>-->
        <input id="fileInput" type="file" ref="fileInput" class="hidden" accept=".pdf" @change="handleClick">
        <div id="pdf"
          @click="$refs.fileInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <feather-icon icon="UploadIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block"/>
          <span class="block">Arrastra o selecciona tu archivo PDF </span>
          <span class="block" v-if="pdf"><b>{{pdf.name}}</b></span> <!-- File -->
        </div>
      </div>

      <!-- CONSTANCIA ASN.1 -->
      <div class="mt-5">
        <!--<vs-divider position="left"><b>Constancia</b></vs-divider>-->
        <input id="asnInput" type="file" ref="asnInput" class="hidden" accept=".asn1" @change="handleClick">
        <div id="asn"
          @click="$refs.asnInput.click()"
          @drop="handleDrop"
          @dragover="handleDragover"
          @dragenter="handleDragover"
          class="px-8 py-16 cursor-pointer text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
          <feather-icon icon="UploadIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block"/>
          <span class="block">Arrastra o selecciona la constancia NOM-151 </span>
          <span class="block" v-if="asn1"><b>{{asn1.name}}</b></span> <!-- File -->
        </div>
        <vs-button v-if="!loading" type="filled" @click.prevent="validateNom" class="mt-5 block">Validar</vs-button>
        <vs-progress class="mt-5" v-if="loading" indeterminate color="primary">Validando ...</vs-progress>
      </div>
    </div>

    <transition name="fade">
      <div class="container" id="results" v-if="result">
        <!--todo: make component-->
        <div class="flex flex-wrap items-center justify-between">
          <h2 class="mb-base mr-3 text-primary">Resultado de tu validación</h2>
          <div class="flex items-center">
            <vs-button class="mb-base mr-3 no-print" color="success" @click="printResult" type="filled">Imprimir reporte</vs-button>
          </div>
        </div>

        <!--Detalles del Documento-->
        <doc-details :name="result['filename']" :hash="result['hash']"/>

        <!-- Firmantes -->

        <!--NOM-->
        <!-- Contancia de conservacion NOM-151 -->
        <vx-card id="nom-151" class="mb-5">
          <nom
            :is-imp-msg-valid="result['tsrResult']['imprintMessageValid']"
            :is-tsr-valid="result['tsrResult']['success']"
            :policy="result['tsrResult']['policy']"
            :signer="result['tsrResult']['signerResults'][0]"
          >
          </nom>
        </vx-card>
        <div class="vx-row no-print">
          <vs-button class="mt-5 block mr-2" @click="restore" type="filled">Validar otro PDF</vs-button>
        </div>
      </div>
    </transition>


  </div>
</template>

<script>
import ValidatorService from '../service/validatorService'
import Util from '../util'

export default {
  props: {
    onSuccess: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      asn1: null,
      pdf: null,
      result: null,
      error: false,
      msgErr: '',
      loading: false
    }
  },
  methods: {

    handleDragover (e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleDrop (e) {
      e.stopPropagation()
      e.preventDefault()
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.alert('Solo se permite un archivo')
      }
      let isAsn = false
      const rawFile = files[0] //

      if (e.currentTarget.id === 'asn') {
        isAsn = true
        if (!this.isAsn(rawFile)) {
          this.alert('Solo se admiten archivos .asn1')
          return false
        }
      } else if (!this.isPdf(rawFile)) {
        this.alert('Solo se admiten archivos .PDF')
        return false
      }
      this.uploadFile(rawFile, isAsn)
    },

    handleClick (e) {
      const files = e.target.files
      const rawFile = files[0]
      if (!rawFile) return
      const isAsn = (e.target.id === 'asnInput')
      this.uploadFile(rawFile, isAsn)
    },
    isPdf (file) {
      return /\.(pdf)$/.test(file.name)
    },

    isAsn (file) {
      return /\.(asn1)$/.test(file.name)
    },

    uploadFile (file, isAsn) {
      this.$refs['fileInput'].value = null
      this.$refs['asnInput'].value = null
      isAsn ? this.asn1 = file : this.pdf = file
    },

    async validateNom () {
      if (!this.validateForm()) return

      const vm = this
      this.loading = true
      const asnb64 = await Util.fileToB64(this.asn1)


      ValidatorService.validateNom(this.pdf, asnb64)
        .then(function (response) {
          vm.result = response.data
        })
        .catch(function (error) {
          vm.error = true
          console.log(error.response.data)
          vm.alert(error.response.data['description'])
        })
        .finally(function () {
          vm.loading = false
        })

    },

    printResult () {
      window.print()
    },

    alert (error) {
      this.$vs.dialog({
        color:'warning',
        title: 'Problema de validación',
        acceptText: 'Aceptar',
        text: `${error}`
      })
    },

    restore () {
      this.asn1 = null
      this.pdf = null
      this.result = null
      this.error =  false
    },

    validateForm () {
      if (!this.pdf || !this.asn1) {
        this.alert('Debes seleccionar un archivo PDF y su constancia de conservación')
        return false
      }
      return true
    }
  }
}
</script>
