class Util {

  static fileToB64 (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result.split(',')[1])
      reader.onerror = error => reject(error)
    })
  }

  static fileToB64Beta (file) {
    return Buffer.from(file, 'base64').toString()
  }


}

export default Util
